import {
  dashboardAxios as axios,
  isAxiosError,
  isConflictAxiosError,
  isModelValidationError,
  isNotFoundAxiosError,
} from "@/lib/axios";
import { BoardNotFoundError } from "@/modules/board/errors/BoardNotFoundError";
import {
  Board,
  BoardWithUser,
  GlobalVariableDefinition,
  MetricWidget,
  Template,
  Widget,
  WidgetMetric,
} from "@/modules/board/models/board";
import { Locale, Report, ReportType, fetchReport } from "@/services/reportService";
import { User } from "@/types/user";
import { isUserIdLara } from "@/utils/isUserLara";
import { ListResult, OrderDirection, Paginable } from "@alanszp/core";
import { clone, groupBy, isNil, reduce, uniq } from "lodash";
import { getUser } from "./userService";
import { ViewOrgDataScope } from "@/modules/board/components/BoardSwitchScope";
import { match } from "@/utils/match";
import { FolderNotFoundError } from "@/modules/board/errors/FolderNotFoundError";
import { RoleHomeBoardCannotBeUnsharedError } from "@/modules/board/errors/RoleHomeBoardCannotBeUnsharedError";
import {
  MetricData,
  MetricDataQueryFilter,
  MetricDataQueryOrderBy,
  MetricDataSource,
} from "@/modules/board/models/metricDataSource";
import { NoDataForPeriodError } from "@/modules/board/errors/NoDataForPeriodError";
import { mergeReport } from "./helpers/reportTransformer/mergeReport";
import { transformReport } from "@/services/helpers/reportTransformer/transformReport";

interface SearchBoardParams extends Partial<Paginable<true>> {
  search?: string;
  owner?: string;
  folderId?: string;
  includesWithoutAccess?: boolean;
  includesOnFolders?: boolean;
}

interface SearchBoardRequestParams extends SearchBoardParams {
  creatorUserReference?: string;
}

export async function createBoard(board: Pick<Board, "name" | "description" | "accessScope">): Promise<Board> {
  const response = await axios.post<Board>(`/v1/boards`, {
    ...board,
  });
  const boardCreated = response.data;
  return boardCreated;
}

export async function duplicateBoard(
  board: Pick<Board, "name" | "description" | "globalVariables" | "sharedRoleReferences" | "accessScope" | "widgets">
): Promise<Board> {
  const response = await axios.post<Board>(`/v1/boards`, {
    ...board,
  });
  const boardDuplicated = response.data;
  return boardDuplicated;
}

export async function moveBoardToFolder(boardToVeMoved: Pick<Board, "id">, folderId: string): Promise<any> {
  const boards = [boardToVeMoved.id];
  await axios.post<Board>(`/v1/boards/folders/${folderId}/content`, {
    boards,
  });
}

export async function deleteBoard(board: Pick<Board, "id">) {
  await axios.delete(`/v1/boards/${board.id}`);
}

export async function searchBoards(params: SearchBoardParams): Promise<ListResult<BoardWithUser>> {
  const parseParams: SearchBoardRequestParams = clone(params);

  const response = await axios.get<ListResult<Board>>(`/v1/boards`, { params: parseParams });

  const userIds = uniq(response.data.elements.map((board) => board.creatorUserReference)).filter(
    (u) => !isUserIdLara(u)
  );

  const settledUsers = await Promise.allSettled(userIds.map((uid) => getUser(uid)));
  const users = settledUsers
    .filter((r) => r.status === "fulfilled")
    .map((r) => (r as PromiseFulfilledResult<User>).value);

  const informError = settledUsers.find((r) => r.status === "rejected") !== undefined;
  if (informError) {
    // If some board creator user is not found, we console.error it so it appears in Sentry
    console.error("Some board creator user is not found", {
      userIds,
    });
  }

  const usersById = groupBy(users, "id");

  const boardsWithUser: BoardWithUser[] = response.data.elements.map((board) => ({
    ...board,
    creatorUser: usersById?.[board.creatorUserReference]?.[0] ?? null,
  }));

  return { ...response.data, elements: boardsWithUser };
}

/**
 * @throws {BoardNotFoundError}
 */
export async function fetchBoard(boardId: string): Promise<BoardWithUser> {
  try {
    const response = await axios.get<Board>(`/v1/boards/${boardId}`);
    return {
      ...response.data,
      creatorUser: isUserIdLara(response.data.creatorUserReference)
        ? null
        : await getUser(response.data.creatorUserReference).catch(() => null),
    };
  } catch (error: unknown) {
    if (isAxiosError(error) && (isNotFoundAxiosError(error) || isModelValidationError(error, "boardId"))) {
      throw new BoardNotFoundError();
    }
    throw error;
  }
}

/**
 * @description PUT /v1/boards/:boardId
 * @param board Board to update
 */
export async function updateBoard(board: Board) {
  await axios.put(`/v1/boards/${board.id}`, board);
}

export interface ShareBoardToExistingUserParams {
  new: false;
  type: "user";
  id: string;
  email: string;
}

export interface ShareBoardToNewUserParams {
  new: true;
  type: "user";
  id?: never;
  email: string;
  firstName: string;
  lastName: string;
  locale: string;
}

export type ShareBoardToUserParams = ShareBoardToExistingUserParams | ShareBoardToNewUserParams;

export interface ShareBoardToRoleParams {
  new: false;
  type: "role";
  role: string;
}

export type ShareBoardParams = ShareBoardToUserParams | ShareBoardToRoleParams;

type ShareBoardBody = {
  usersToShare: string[];
  usersToCreate: { email: string; firstName: string; lastName: string; locale: string }[];
  rolesToShare: string[];
};

export async function shareBoard(boardId: string, params: ShareBoardParams) {
  const body = match(params, "type", {
    user: (userParams): ShareBoardBody => {
      if (userParams.new) {
        return {
          usersToCreate: [
            {
              email: userParams.email,
              firstName: userParams.firstName,
              lastName: userParams.lastName,
              locale: userParams.locale,
            },
          ],
          usersToShare: [],
          rolesToShare: [],
        };
      }

      return {
        usersToShare: [userParams.id],
        usersToCreate: [],
        rolesToShare: [],
      };
    },
    role: ({ role }): ShareBoardBody => ({
      rolesToShare: [role],
      usersToShare: [],
      usersToCreate: [],
    }),
  });

  await axios.post(`/v1/boards/${boardId}/share`, body);
}

export async function revokeBoardAccessToUser(boardId: string, userId: string) {
  await axios.post(`/v1/boards/${boardId}/unshare/${userId}`);
}

enum RevokeBoardAccessToRoleResponseErrorCode {
  ROLE_HOME_BOARD_CANNOT_BE_UNSHARED = "role_home_board_cannot_be_unshared",
}

export async function revokeBoardAccessToRole(boardId: string, roleId: string) {
  try {
    await axios.post(`/v1/boards/${boardId}/unshare/roles/${roleId}`);
  } catch (error) {
    if (
      isAxiosError(error) &&
      isConflictAxiosError(error, RevokeBoardAccessToRoleResponseErrorCode.ROLE_HOME_BOARD_CANNOT_BE_UNSHARED)
    ) {
      throw new RoleHomeBoardCannotBeUnsharedError();
    }
    throw error;
  }
}

export interface SearchFoldersParams extends Partial<Paginable<true>> {
  search?: string;
  showEmpty?: boolean;
  includesWithoutAccess?: boolean;
}

export type Folder = {
  id: string;
  name: string;
  creatorUserReference: string;
  createdAt: string;
  updatedAt: string;
  boardsCount: number;
};

export async function searchFolders(params: SearchFoldersParams): Promise<ListResult<Folder>> {
  const response = await axios.get<ListResult<Folder>>(`/v1/boards/folders`, { params });
  return response.data;
}

export async function getFolder(folderId: string, includesWithoutAccess?: boolean): Promise<Folder> {
  try {
    const response = await axios.get<Folder>(`/v1/boards/folders/${folderId}`, {
      params: { includesWithoutAccess: includesWithoutAccess },
    });
    return response.data;
  } catch (error: unknown) {
    if (isAxiosError(error) && (isNotFoundAxiosError(error) || isModelValidationError(error, "folderId"))) {
      throw new FolderNotFoundError();
    }
    throw error;
  }
}

export async function createFolder(name: string): Promise<Folder> {
  const response = await axios.post<Folder>("/v1/boards/folders", { name });
  return response.data;
}

// boardsRouter.patch(
//   "/folders/:folderId",
//   auditLog("folder.patch", (req) => ({
//     targetRef: req.params.folderId,
//     metadata: { [CommonMetadataKeys.CHANGES]: req.body },
//   })),
//   updateFolderController
// );

export async function updateFolder(folderId: string, folder: Partial<Folder>) {
  await axios.patch(`/v1/boards/folders/${folderId}`, { ...folder });
}

export async function removeFolder(folderId: string, deleteBoards: boolean) {
  // await axios.delete(`/v1/boards/folders/${folderId}`, { params: { deleteBoards } });
  throw new Error("Not implemented");
}

/**
 * Generic function to map widget filters to report filters
 */
function mapWidgetFiltersToReportFilters(filters: MetricDataQueryFilter): URLSearchParams {
  const urlSearchParams = new URLSearchParams();

  Object.entries(filters ?? {}).forEach(([key, value]) => {
    if (key === "viewOrgData[eq]") {
      // Map viewOrgData filter to its boolean counterpart
      const viewOrgData = value === ViewOrgDataScope.ALL_ORG ? "true" : "false";
      urlSearchParams.set("viewOrgData", viewOrgData);
      return;
    }

    if (!isNil(value)) {
      urlSearchParams.append(key, value.toString());
    }
  });

  return urlSearchParams;
}

function mapOrderByFields(orderByObject?: MetricDataQueryOrderBy): {
  /**
   * Columns to order by separated by commas, index based
   * @example "createdAt,updatedAt"
   */
  orderBy?: string;
  /**
   * Order direction for each column separated by commas
   * @example "ASC,DESC"
   */
  orderDirection?: string;
} {
  if (!orderByObject) return {};

  /**
   * Split the object into two arrays, one for the columns and one for the directions
   */
  const [orderBy, orderDirection] = Object.entries(orderByObject).reduce(
    (acc, [field, dir]) => {
      acc[0].push(field);
      acc[1].push(dir);
      return acc;
    },
    [[] as string[], [] as OrderDirection[]]
  );

  return {
    orderBy: orderBy.join(","),
    orderDirection: orderDirection.join(","),
  };
}

/**
 * Relation between WidgetMetric and ReportType
 */
const METRIC_TO_REPORT_MAP: Record<WidgetMetric, ReportType> = {
  [WidgetMetric.ENPS]: ReportType.METRIC_ENPS_SCORE,
  [WidgetMetric.ENPS_ANSWERS]: ReportType.METRIC_ENPS_ANSWERS,
  [WidgetMetric.QUESTIONS]: ReportType.METRIC_QUESTIONS,
  [WidgetMetric.RESPONSE_RATE]: ReportType.METRIC_RESPONSE_RATE,
  [WidgetMetric.CASES]: ReportType.METRIC_CASES,
  [WidgetMetric.MOOD]: ReportType.METRIC_MOOD,
  [WidgetMetric.MOOD_ANSWERS]: ReportType.METRIC_MOOD_ANSWERS,
  [WidgetMetric.DRIVER]: ReportType.METRIC_DRIVER_SCORE,
  [WidgetMetric.DRIVER_ANSWERS]: ReportType.METRIC_DRIVER_ANSWERS,
  [WidgetMetric.LARA_SCORE]: ReportType.METRIC_LARA_SCORE,
  [WidgetMetric.CHATS]: ReportType.METRIC_CHATS,
  [WidgetMetric.EMPLOYEES]: ReportType.METRIC_EMPLOYEES,
  [WidgetMetric.ONBOARDING_AND_OFFBOARDING]: ReportType.METRIC_ONBOARDING_AND_OFFBOARDING,
  [WidgetMetric.INDIVIDUAL_RESPONSES]: ReportType.METRIC_INDIVIDUAL_RESPONSES,
  [WidgetMetric.METRIC_HELPDESK_CONVERSATIONS]: ReportType.METRIC_HELPDESK_CONVERSATIONS,
  [WidgetMetric.METRIC_HELPDESK_USED_ITEMS]: ReportType.METRIC_HELPDESK_USED_ITEMS,
};

export function retrocompatibleMetricData(widget: MetricWidget): MetricData {
  return (
    widget.data ??
    (widget.metric
      ? {
          globals: {
            groupBy: widget.groupBy,
            filters: widget.filters,
            breakdown: widget.breakdown,
          },
          sources: [
            {
              query: {
                metric: widget.metric,
                benchmark: widget.benchmark,
                orderBy: widget.orderBy,
                timeSegmentation: widget.timeSegmentation,
              },
            },
          ],
          transform: {
            orderBy: widget.frontOrderBy,
          },
        }
      : { sources: [] })
  );
}

/**
 * Fetches a Report from the Report API based on a configured Widget
 * @param widget Widget from the Board
 * @returns Generic Report
 * This could be cached in the future to avoid fetching the same report multiple times
 */
export async function fetchWidgetMetricReport(
  widget: MetricWidget,
  locale?: Locale,
  boardId?: string
): Promise<Report> {
  const data: MetricData = retrocompatibleMetricData(widget);

  if (data.sources.length === 0) {
    throw new NoDataForPeriodError();
  }

  const results: [Report, MetricDataSource][] = await Promise.all(
    data.sources.map(async (source) => [
      (await fetchReport(
        METRIC_TO_REPORT_MAP[source.query.metric],
        {
          filters: mapWidgetFiltersToReportFilters({ ...source.query.filters, ...data.globals?.filters }),
          groupBy: data.globals?.groupBy ?? source.query.groupBy,
          breakdown: data.globals?.breakdown ?? source.query.breakdown,
          ...mapOrderByFields(source.query.orderBy),
          locale,
        },
        { widgetId: widget.id, boardId },
        source.query.benchmark ?? false
      )) ?? { headers: {}, data: [] },
      source,
    ])
  );

  return transformReport(
    data.transform,
    reduce(
      results,
      (accReport, [currentReport, currentDataSource]) => mergeReport(accReport, currentDataSource, currentReport),
      {
        headers: {},
        data: [],
      }
    )
  );
}

export async function getTemplate(templateId: string): Promise<Template> {
  const response = await axios.get<Template>(`/v1/boards/templates/${templateId}`);
  return response.data;
}

export async function createTemplateFromBoard(boardId: string): Promise<Template> {
  const response = await axios.post<Template>(`/v1/boards/templates/${boardId}`);
  return response.data;
}

interface UpdateTemplateBody {
  name: string;
  description: string | null;
  widgets: Widget[];
  globalVariables: GlobalVariableDefinition[];
}

export async function updateBoardTemplate(templateId: string, body: UpdateTemplateBody) {
  await axios.put(`/v1/boards/templates/${templateId}`, body);
}
