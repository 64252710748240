import { EmployeeFieldValues, getEmployeeFieldValues } from "@/services/employeeService";
import { leavingReasonTypeLocale, moduleLocale, tenureGroupLocale } from "@/utils/localizeConstants";
import { isDate, parseISO } from "date-fns";
import DOMPurify from "dompurify";
import { TFunction } from "i18next";
import { isNil, isNull, omit } from "lodash";
import { AutocompleteOption } from "../InputAutocomplete";
import { AutocompleteFilterConfiguration, DateFilterConfiguration, FilterConfiguration } from "./FilterConfiguration";
import { FilterType } from "./useFilters";

// Used in js-doc
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { useModuleFilter } from "@/hooks/useModuleFilter";
import { ALL_MODULE_VALUES, ALL_TENURE_GROUP, LeavingReasonType, Module } from "@/types/common";
import { EmployeeFieldTranslationGetter } from "@/hooks/useEmployeeFields";

export const parseArrayValue = (value: string): string | Date | boolean | string[] => {
  if (isNil(value)) return [];
  return value?.includes(",") ? value.split(",") : value;
};

export function assertParseSpecialFormatValue(value: string): string | Date | boolean | string[] {
  let possibleFormat: string | Date | boolean | string[] = decodeURIComponent(value.replace(/\+/g, "%20"));

  if (isDate(possibleFormat)) {
    return parseISO(possibleFormat);
  }

  if (possibleFormat === "false") return false;
  if (possibleFormat === "true") return true;

  possibleFormat = parseArrayValue(possibleFormat);

  return possibleFormat;
}

export function safeSetValuesFromUrl<T extends object>(
  searchParams: URLSearchParams,
  defaultValues: Record<string, string | number | Date | boolean | string[]>
): T {
  const newValues = {};
  Object.keys(defaultValues).forEach((key) => {
    const value = searchParams.get(key);

    if (!isNull(value)) {
      const safeValue = DOMPurify.sanitize(value); // prevent xss attack

      newValues[key] = safeValue === "" ? null : assertParseSpecialFormatValue(safeValue);
    } else {
      newValues[key] = defaultValues[key];
    }
  });

  return newValues as T;
}

export function rangeDateFields(
  t: TFunction,
  /**
   * Override default configuration for from field
   */
  from: Partial<DateFilterConfiguration> = {},
  /**
   * Override default configuration for to field
   */
  to: Partial<DateFilterConfiguration> = {}
): DateFilterConfiguration[] {
  return [
    {
      type: FilterType.DATE,
      name: "from",
      label: t("common.filters.labels.from"),
      fieldConfig: {
        maxDate: (values) => (values.to as string) || undefined,
        ...from.fieldConfig,
      },
      ...from,
    },
    {
      type: FilterType.DATE,
      name: "to",
      label: t("common.filters.labels.to"),
      fieldConfig: {
        minDate: (values) => (values.from as string) || undefined,
        ...to.fieldConfig,
      },
      ...to,
    },
  ];
}

export const DRIVER_DEFAULT_VALUE = "all";
export const SUB_DRIVER_DEFAULT_VALUE = "all";

export function driverFields(t: TFunction): FilterConfiguration[] {
  return [
    {
      type: FilterType.SELECT_DRIVER,
      name: "driver",
      label: t("textAnswers.home.filters.driver"),
      defaultValue: DRIVER_DEFAULT_VALUE,
      fieldConfig: {
        type: "driver",
      },
    },
    {
      type: FilterType.SELECT_DRIVER,
      name: "subDriver",
      label: t("textAnswers.home.filters.subdriver"),
      visible: (values) => Boolean(values.driver) && values.driver !== DRIVER_DEFAULT_VALUE,
      defaultValue: SUB_DRIVER_DEFAULT_VALUE,
      fieldConfig: {
        type: "subDriver",
        driverCode: (values) => values.driver as string,
      },
    },
  ];
}

function mapRequestFieldResponseToAutocomplete(response): AutocompleteOption[] {
  return response.fieldValues.map((fieldValue) => ({
    label: fieldValue,
    value: fieldValue,
  }));
}

export function departmentField(
  t: TFunction,
  getEmployeeFieldTranslation: EmployeeFieldTranslationGetter
): AutocompleteFilterConfiguration {
  return {
    type: FilterType.AUTOCOMPLETE,
    name: "department",
    label: getEmployeeFieldTranslation("department", t("questions.home.filters.department")),
    fieldConfig: {
      options: (filters) => () =>
        getEmployeeFieldValues("department", omit(filters, ["department"])).then(mapRequestFieldResponseToAutocomplete),
    },
  };
}

export function locationField(
  t: TFunction,
  getEmployeeFieldTranslation: EmployeeFieldTranslationGetter
): AutocompleteFilterConfiguration {
  return {
    type: FilterType.AUTOCOMPLETE,
    name: "location",
    label: getEmployeeFieldTranslation("location", t("questions.home.filters.location")),
    fieldConfig: {
      options: (filters) => () =>
        getEmployeeFieldValues("location", omit(filters, ["location"])).then(mapRequestFieldResponseToAutocomplete),
    },
  };
}

export function divisionField(
  t: TFunction,
  getEmployeeFieldTranslation: EmployeeFieldTranslationGetter
): AutocompleteFilterConfiguration {
  return {
    type: FilterType.AUTOCOMPLETE,
    name: "division",
    label: getEmployeeFieldTranslation("division", t("questions.home.filters.division")),
    fieldConfig: {
      options: (filters) => () =>
        getEmployeeFieldValues("division", omit(filters, ["division"])).then(mapRequestFieldResponseToAutocomplete),
    },
  };
}

export function subDepartmentField(
  t: TFunction,
  getEmployeeFieldTranslation: EmployeeFieldTranslationGetter
): AutocompleteFilterConfiguration {
  return {
    type: FilterType.AUTOCOMPLETE,
    minWidth: "200px",
    name: "subDepartment",
    label: getEmployeeFieldTranslation("subDepartment", t("questions.home.filters.subDepartment")),
    fieldConfig: {
      options: (filters) => () =>
        getEmployeeFieldValues("subDepartment", omit(filters, ["subDepartment"])).then(
          mapRequestFieldResponseToAutocomplete
        ),
    },
  };
}

export function cityField(
  t: TFunction,
  getEmployeeFieldTranslation: EmployeeFieldTranslationGetter
): AutocompleteFilterConfiguration {
  return {
    type: FilterType.AUTOCOMPLETE,
    name: "city",
    label: getEmployeeFieldTranslation("city", t("questions.home.filters.city")),
    fieldConfig: {
      options: (filters) => () =>
        getEmployeeFieldValues("city", omit(filters, ["city"])).then(mapRequestFieldResponseToAutocomplete),
    },
  };
}

export function countryField(t: TFunction): AutocompleteFilterConfiguration {
  return {
    type: FilterType.AUTOCOMPLETE,
    name: "country",
    label: t("questions.home.filters.country"),
    fieldConfig: {
      options: (filters) => () =>
        getEmployeeFieldValues("country", omit(filters, ["country"])).then(mapRequestFieldResponseToAutocomplete),
    },
  };
}

export function tenureField(t: TFunction): AutocompleteFilterConfiguration {
  return {
    type: FilterType.AUTOCOMPLETE,
    name: "tenure",
    label: t("questions.home.filters.tenure"),
    fieldConfig: {
      options: Object.values(ALL_TENURE_GROUP).map((value) => ({ label: tenureGroupLocale(t, value), value: value })),
    },
  };
}

/**
 * If you want to exclude a module from the filter, pass it in the exclude array
 * Take a look into {@link useModuleFilter} if you want to exclude modules dynamically based on split flags.
 */
export function moduleField(t: TFunction, exclude: Module[] = []): AutocompleteFilterConfiguration {
  return {
    type: FilterType.AUTOCOMPLETE,
    name: "module",
    label: t("questions.home.filters.module"),
    fieldConfig: {
      options: Object.values(ALL_MODULE_VALUES)
        .filter((m) => !exclude.includes(m))
        .map((value) => ({ label: moduleLocale(t, value), value: value })),
    },
    minWidth: "200px",
  };
}

export function mapLeavingReasonTypeToAutocomplete(t: TFunction, response: EmployeeFieldValues): AutocompleteOption[] {
  return response.fieldValues.map((fieldValue: LeavingReasonType) => ({
    label: leavingReasonTypeLocale(t, fieldValue),
    value: fieldValue,
  }));
}

export function offboardingLeavingReasonTypeField(t: TFunction): AutocompleteFilterConfiguration {
  return {
    type: FilterType.AUTOCOMPLETE,
    name: "leavingReasonType",
    label: t("questions.home.filters.leavingReasonType"),
    visible: (values) => values.module === Module.OFFBOARDING,
    minWidth: 200,
    fieldConfig: {
      options: () => () =>
        getEmployeeFieldValues("leavingReasonType", { showFormer: true }).then((response) =>
          mapLeavingReasonTypeToAutocomplete(t, response)
        ),
    },
  };
}
