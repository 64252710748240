import { createContext, useEffect, useReducer } from "react";
import type { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import { dashboardAxios, isAxiosError, originalOrgDashboardAxios } from "@/lib/axios";
import type { User } from "@/types/user";
import { verifyPartialToken } from "@/utils/jwt";
import { baseUrl } from "@/config";
import { getUserMe } from "@/services/userService";
import * as Sentry from "@sentry/react";
import { trackLogin } from "../track";
import * as track from "@/lib/track";
import {
  AuthResponse,
  googleLogin,
  passwordLogin,
  totpChallenge,
  totpGenerateSecret,
  totpRegister,
  microsoftLogin,
  getCurrentJwtUser,
  getPermissions,
  sessionLogout,
  doImpersonate,
  stopImpersonating as executeStopImpersonating,
} from "@/services/authService";
import { CredentialResponse } from "@react-oauth/google";
import { JWTUser, PermissionService } from "@alanszp/jwt";
import { identity } from "lodash";
import { MfaChallengeFailedError, MfaRegisterFailedError } from "../errors/MfaChallengeFailedError";

export enum LoginServerSideErrors {
  EMAIL_PASSWORD = "email_password",
  NETWORK = "network",
  GENERIC = "generic",
  COOKIE_NOT_SET = "cookie_not_set",
}

export enum AuthProviderType {
  GOOGLE = "google",
  PASSWORD = "password",
  MICROSOFT = "microsoft",
}

export interface LoginGoogleParams {
  provider: AuthProviderType.GOOGLE;
  data: CredentialResponse;
}

export interface LoginMicrosoftParams {
  provider: AuthProviderType.MICROSOFT;
  data: { uniqueId: string; accessToken: string };
}

export interface LoginPasswordParams {
  provider: AuthProviderType.PASSWORD;
  data: {
    email: string;
    password: string;
  };
}

export type LoginParams = LoginGoogleParams | LoginPasswordParams | LoginMicrosoftParams;

const RESOLUTION_STRATEGY: Record<AuthProviderType, (data: LoginParams["data"]) => Promise<AuthResponse>> = {
  [AuthProviderType.PASSWORD]: (data: LoginPasswordParams["data"]) => passwordLogin(data),
  [AuthProviderType.GOOGLE]: (data: LoginGoogleParams["data"]) => googleLogin(data),
  [AuthProviderType.MICROSOFT]: (data: LoginMicrosoftParams["data"]) => microsoftLogin(data),
};

function getTokenWithLoginData({ provider, data }: LoginParams): Promise<AuthResponse> {
  const strategy = RESOLUTION_STRATEGY[provider];
  if (!strategy) throw new Error("Invalid strategy");
  return strategy(data);
}

// TODO: Fix real reason. Initialize is being called twice.
let initializeAlreadyCalled = false;

export interface AuthState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  isImpersonating: boolean;
  needsRevalidation: boolean;
  user: User | null;
  jwtUser: JWTUser | null;
  organizationReference: string | null;
  policy: "mfa_required" | "mfa_challenge" | null;
  secret: string | null;
  totp: string | null;
  url: string | null;
  email: string | null;
}

interface AuthContextValue extends AuthState {
  platform: "JWT";
  login: (params: LoginParams) => Promise<void>;
  logout: () => Promise<void>;
  changeOrg: (organizationReference: string | null) => Promise<void>;
  mfaRegister: (code: string) => Promise<void>;
  mfaChallenge: (code: string) => Promise<void>;
  reloadUser: () => Promise<void>;
  impersonate: (userId: string) => Promise<void>;
  stopImpersonating: () => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type TOTPAction = {
  type: "TOTP";
  payload: {
    policy: "mfa_challenge" | "mfa_required";
    secret: string | null;
    url: string | null;
    email: string | null;
  };
};

type InitializeAction = {
  type: "INITIALIZE";
  payload: {
    isAuthenticated: boolean;
    user: User | null;
    jwtUser: JWTUser | null;
    organizationReference?: string | null;
  };
};

type LoginAction = {
  type: "LOGIN";
  payload: {
    user: User;
    jwtUser: JWTUser;
  };
};

type LogoutAction = {
  type: "LOGOUT";
};

type ChangeOrgAction = {
  type: "CHANGE_ORG";
  payload: {
    organizationReference: string;
  };
};

type Action = InitializeAction | LoginAction | LogoutAction | ChangeOrgAction | TOTPAction;

const initialState: AuthState = {
  isAuthenticated: false,
  isImpersonating: false,
  needsRevalidation: false,
  isInitialized: false,
  user: null,
  jwtUser: null,
  organizationReference: "",
  policy: null,
  secret: null,
  totp: null,
  url: null,
  email: null,
};

const setOrganizationReference = (organizationReference?: string | null): void => {
  if (organizationReference) {
    localStorage.setItem("org", organizationReference);
    dashboardAxios.defaults.baseURL = baseUrl.dashboardApi + "/" + organizationReference;
  } else {
    localStorage.removeItem("org");
    dashboardAxios.defaults.baseURL = baseUrl.dashboardApi;
  }
};

function setSession(jwtUser: JWTUser, organizationReference: string): void {
  originalOrgDashboardAxios.defaults.baseURL = baseUrl.dashboardApi + "/" + jwtUser.organizationReference;
  const scope = Sentry.getCurrentScope();
  scope.setUser({ id: jwtUser.id, organizationReference: jwtUser.organizationReference });
  setOrganizationReference(organizationReference);
}

function dropSession(): void {
  originalOrgDashboardAxios.defaults.baseURL = baseUrl.dashboardApi;
  const scope = Sentry.getCurrentScope();
  scope.setUser(null);
  setOrganizationReference(null);
}

const instantiatePermissionService = async () => {
  /**
   * If something goes wrong, in the permission service,
   * will log it to the console and Sentry will catch it.
   */
  const frontLogger = {
    debug: identity,
    info: identity,
    warn: identity,
    trace: identity,
    child: identity,
    event: identity,
    error: (message: string, context: unknown) => console.error(`[Permission Service Logger] ${message}`, context),
  };

  const permissionService = new PermissionService(frontLogger, getPermissions);
  JWTUser.setPermissionService(permissionService);

  // Fetch permissions and cache them, only once
  await permissionService.reloadPermissionCache();
};

const handlers: Record<string, (state: AuthState, action: Action) => AuthState> = {
  TOTP: (state: AuthState, action: TOTPAction): AuthState => {
    const { policy, secret, url, email } = action.payload;
    return {
      ...state,
      policy,
      secret,
      url,
      email,
    };
  },
  INITIALIZE: (state: AuthState, action: InitializeAction): AuthState => {
    const { isAuthenticated, user, jwtUser, organizationReference } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      isImpersonating: jwtUser?.isImpersonating() ?? false,
      jwtUser,
      user,
      organizationReference: organizationReference || user?.organizationReference || null,
    };
  },
  LOGIN: (state: AuthState, action: LoginAction): AuthState => {
    const { user, jwtUser } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
      jwtUser,
      isImpersonating: false,
      organizationReference: user.organizationReference,
      policy: null,
    };
  },
  LOGOUT: (state: AuthState): AuthState => ({
    ...state,
    isAuthenticated: false,
    isImpersonating: false,
    user: null,
    jwtUser: null,
    organizationReference: null,
  }),
  CHANGE_ORG: (state: AuthState, action: ChangeOrgAction): AuthState => {
    const { organizationReference } = action.payload;

    return {
      ...state,
      organizationReference,
    };
  },
};

const reducer = (state: AuthState, action: Action): AuthState =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: "JWT",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  changeOrg: () => Promise.resolve(),
  mfaRegister: () => Promise.resolve(),
  mfaChallenge: () => Promise.resolve(),
  reloadUser: () => Promise.resolve(),
  impersonate: () => Promise.resolve(),
  stopImpersonating: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = async (): Promise<void> => {
    try {
      const jwtUser = await getCurrentJwtUser();

      if (!jwtUser) {
        return dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
            jwtUser: null,
          },
        });
      }

      const organizationReference = window.localStorage.getItem("org") || jwtUser.organizationReference;

      setSession(jwtUser, organizationReference);

      const user = await getUserMe();
      if (!user) {
        await sessionLogout();
        return dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
            jwtUser: null,
            organizationReference,
          },
        });
      }

      if (user.organizationReference !== "lara" && user.organizationReference !== organizationReference) {
        dropSession();
        await sessionLogout();
        return dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
            jwtUser: null,
            organizationReference: null,
          },
        });
      }

      await instantiatePermissionService();

      dispatch({
        type: "INITIALIZE",
        payload: {
          isAuthenticated: true,
          user,
          jwtUser,
          organizationReference,
        },
      });

      track.identifyTrackingUser(user);
    } catch (err) {
      dispatch({
        type: "INITIALIZE",
        payload: {
          isAuthenticated: false,
          user: null,
          jwtUser: null,
        },
      });
    }
  };

  useEffect(() => {
    track.configure();

    if (initializeAlreadyCalled) return;
    initializeAlreadyCalled = true;
    initialize();
  }, []);

  const verifyUser = async (jwtUser: JWTUser): Promise<void> => {
    dropSession();
    setSession(jwtUser, jwtUser.organizationReference);
    const user = await getUserMe().catch((error) => {
      console.error(error);
      if (error.request) {
        throw new Error(LoginServerSideErrors.NETWORK);
      }
      throw new Error(LoginServerSideErrors.GENERIC);
    });

    if (!user) {
      throw new Error("Login error");
    }

    track.identifyTrackingUser(user);

    trackLogin(user);

    await instantiatePermissionService();

    dispatch({
      type: "LOGIN",
      payload: {
        user,
        jwtUser,
      },
    });
  };

  const mfaRegister = async (code: string): Promise<void> => {
    try {
      await totpRegister(state.secret!, code);
      await mfaChallenge(code);
    } catch (err) {
      if (isAxiosError(err) && err.response?.data?.code === "mfa_challenge_failed") {
        throw new MfaRegisterFailedError();
      }
      throw new Error(err);
    }
  };

  const mfaChallenge = async (code: string): Promise<void> => {
    try {
      await totpChallenge(code);
      const jwtUser = await getCurrentJwtUser();
      if (!jwtUser) {
        throw new Error("Login error");
      }
      await verifyUser(jwtUser);
    } catch (err) {
      if (isAxiosError(err) && err.response?.data?.code === "mfa_challenge_failed") {
        throw new MfaChallengeFailedError();
      }
      throw new Error(err);
    }
  };

  const login = async (params: LoginParams): Promise<any> => {
    const authData = await getTokenWithLoginData(params);

    const { policyCheckStep, policyCheck, partialLoginToken } = authData;

    if (policyCheckStep && policyCheck && partialLoginToken) {
      const jwtUser = await verifyPartialToken(partialLoginToken);
      const organizationReference = jwtUser.organizationReference;
      if (policyCheck.policy === "mfa_required") {
        setSession(jwtUser, organizationReference);
        const totpResponse = await totpGenerateSecret(organizationReference);

        dispatch({
          type: "TOTP",
          payload: {
            secret: totpResponse.secret,
            url: totpResponse.url,
            email: totpResponse.email,
            policy: policyCheck.policy,
          },
        });

        return;
      }
      if (policyCheck.policy === "mfa_challenge") {
        setSession(jwtUser, organizationReference);

        dispatch({
          type: "TOTP",
          payload: {
            secret: null,
            url: null,
            email: null,
            policy: policyCheck.policy,
          },
        });

        return;
      }
    }

    const jwtUser = await getCurrentJwtUser();
    if (!jwtUser) {
      // If no jwtUser at this point, something went wrong
      dispatch({
        type: "INITIALIZE",
        payload: {
          isAuthenticated: false,
          user: null,
          jwtUser: null,
        },
      });
      throw new Error(LoginServerSideErrors.COOKIE_NOT_SET);
    }

    await verifyUser(jwtUser);
  };

  const logout = async (): Promise<void> => {
    dropSession();
    dispatch({ type: "LOGOUT" });
    track.logoutTrackingUser();
    await sessionLogout();
  };

  const changeOrg = async (organizationReference: string | null): Promise<void> => {
    if (!organizationReference) return;

    setOrganizationReference(organizationReference);

    dispatch({
      type: "CHANGE_ORG",
      payload: {
        organizationReference,
      },
    });
  };

  const impersonate = async (userId: string): Promise<void> => {
    if (!userId || !state.organizationReference) return;
    await doImpersonate(state.organizationReference!, userId);
    window.location.reload();
  };

  const stopImpersonating = async (): Promise<void> => {
    await executeStopImpersonating();
    window.location.reload();
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: "JWT",
        login,
        logout,
        changeOrg,
        mfaRegister,
        mfaChallenge,
        reloadUser: initialize,
        impersonate,
        stopImpersonating,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
