import { dashboardAxios as axios } from "@/lib/axios";
import { cancelTokenManager } from "@/utils/cancelTokenManager";
import {
  AuditLog,
  Employee,
  EmployeeBasics,
  EmployeeBasicsWithLastInteraction,
  SegmentOwnerType,
} from "@/types/common";
import { entries, flatten, isArray, isEmpty, isNil } from "lodash";
import fileDownload from "js-file-download";
import { ListResult, Orderable, Paginable } from "@alanszp/core";
import type { FilterValues as AdvanceFilterValues } from "@/components/advanced-filters/useAdvancedFilters";
import type { FilterValues as OldFilterValues } from "@/components/filters/useFilters";
import { sanitizeParamsService } from "@/utils/sanitizeParamsService";

export type SearchEmployeeOrderableFields = "name" | "department" | "principalManagerName";
export type SearchEmployeeWithChatInteractionOrderableFields =
  | SearchEmployeeOrderableFields
  | "lastChatDate"
  | "lastAnsweredChatDate"
  | "employeeEngagementScore";

export interface EmployeeListParams
  extends Partial<Orderable<SearchEmployeeOrderableFields>>,
    Partial<Paginable<false>> {
  s?: string;
  department?: string;
  hrbpId?: string;
  principalManagerId?: string;
  formerEmployees?: boolean;
  contactEnabled?: boolean;
  reachable?: string;
  teamId?: string;
}

function sanitizeParams(
  params?: EmployeeListParams | EmployeeWithLastInteractionParams
): EmployeeListParams | EmployeeWithLastInteractionParams {
  const safeParams = { ...params } as EmployeeListParams | EmployeeWithLastInteractionParams;

  if (isEmpty(safeParams.s)) {
    delete safeParams["s"];
  }

  return {
    ...safeParams,
    // this should be done because useFilter cannot define a value as "true" or "false"
    reachable: params?.reachable === "1" ? "true" : params?.reachable === "0" ? "false" : undefined,
  };
}

type EmployeeWithLastInteractionParams = Omit<EmployeeListParams, "orderBy"> &
  Partial<Orderable<SearchEmployeeWithChatInteractionOrderableFields>>;

export async function employeeWithLastInteraction(
  rawParams?: Partial<EmployeeWithLastInteractionParams>
): Promise<ListResult<EmployeeBasicsWithLastInteraction>> {
  const response = await axios.get<ListResult<EmployeeBasicsWithLastInteraction>>("/v2/employees/chat", {
    params: sanitizeParams(rawParams),
  });
  return response.data;
}

export async function exportEmployeeWithLastInteraction(
  locale: string,
  format: "csv" | "xlsx",
  rawParams?: EmployeeListParams
): Promise<void> {
  const response = await axios.post<string>(`/v2/employees/chat/export/${format}`, null, {
    params: { ...sanitizeParams(rawParams), locale },
    responseType: "blob",
  });

  const fileName = `collaborators-${Date.now()}.${format}`;
  fileDownload(response.data, fileName);
}

export interface SearchEmployeesParams
  extends Partial<Paginable<true>>,
    Partial<Orderable<SearchEmployeeOrderableFields>> {
  searchTerm?: string;
  manager?: boolean;
  id?: string | string[];
  showFormer?: boolean;
}

export async function searchEmployee(
  params?: SearchEmployeesParams,
  extraParamCancelToken = ""
): Promise<ListResult<EmployeeBasics>> {
  const safeParams: {
    s?: string;
    manager?: boolean;
    id?: string;
    showFormer?: boolean;
    page?: number;
    pageSize?: number;
  } = {};

  if (params?.searchTerm) {
    safeParams.s = params.searchTerm;
  }

  if (params?.manager) {
    safeParams.manager = params.manager;
  }

  if (params?.id) {
    safeParams.id = isArray(params.id) ? params.id.join(",") : params.id;
  }

  if (params?.showFormer) {
    safeParams.showFormer = params.showFormer;
  }

  if (params?.page) {
    safeParams.page = params.page;
  }

  if (params?.pageSize) {
    safeParams.pageSize = params.pageSize;
  }

  const response = await axios.get<ListResult<EmployeeBasics>>(`/v2/employees`, {
    params: safeParams,
    cancelToken: cancelTokenManager.create(`searchEmployees${extraParamCancelToken}`).token,
  });

  return response.data;
}

export async function getEmployee(employeeId: string): Promise<Employee> {
  const response = await axios.get<Employee>(`/v1/employees/${employeeId}`);
  return response.data;
}

export async function getEmployeeSummary(employeeId: string): Promise<Employee> {
  const response = await axios.get<Employee>(`/v1/employees/${employeeId}/summary`);
  return response.data;
}

export async function getEmployeeAuditLogs(employeeId: string): Promise<AuditLog[]> {
  const response = await axios.get<{ logs: AuditLog[] }>(`/v1/employees/${employeeId}/audit`);
  return response.data.logs;
}

export type EmployeeFieldFilter = AdvanceFilterValues | OldFilterValues;

function mapFiltersToParams(filters?: EmployeeFieldFilter) {
  const params = {};
  entries(filters || {}).forEach(([filterName, filterValue]) => {
    if (filterValue && filterValue.operator && filterValue.value) {
      const [key, value] = [`${filterName}[${filterValue.operator}]`, filterValue.value];
      params[key] = value;
      return;
    }

    if (!isNil(filterValue)) {
      params[filterName] = isArray(filterValue) ? filterValue.join(",") : filterValue.toString();
      return;
    }
  });

  return sanitizeParamsService(params);
}

interface EmployeeFieldValuesResponse {
  headers: {
    value: string;
  };
  data: { keys: ["values"]; values: string[][] };
}

export interface EmployeeFieldValues {
  fieldName: string;
  fieldValues: string[];
}

export async function getEmployeeFieldValues(
  fieldName: string,
  filters?: EmployeeFieldFilter
): Promise<EmployeeFieldValues> {
  const params = mapFiltersToParams(filters);
  const response = await axios.get<EmployeeFieldValuesResponse | undefined>("/v2/reports/employeeFieldValues/compact", {
    params: { ...params, field: fieldName, translate: false },
  });

  const fieldValues = flatten(response.data?.data?.values ?? []);
  return { fieldName, fieldValues };
}

export interface EmployeeDropdown {
  id: string;
  fullName: string;
  avatar: string;
  jobTitle: string;
}

export type SearchEmployeeDropdownParams = { search?: string } & Paginable<true>;

export async function searchEmployeeDropdown(
  params?: SearchEmployeeDropdownParams,
  extraStringCancelToken = ""
): Promise<ListResult<EmployeeDropdown>> {
  const response = await axios.get<ListResult<EmployeeDropdown>>("/v2/employees/dropdown", {
    params,
    cancelToken: cancelTokenManager.create(`searchEmployeesDropdown-${extraStringCancelToken}`).token,
  });
  return response.data;
}

export type EmployeeFieldOptions = {
  actions: {
    allowFiltering: boolean;
    allowGrouping: boolean;
    allowSorting: boolean;
  };
  visibility: {
    showInEmployeeDetail: boolean;
    showInEmployeeSummary: boolean;
    showInCaseDetail: boolean;
  };
};

export enum EmployeeFieldType {
  /**
   * Text field
   * Supports any string value
   */
  TEXT = "TEXT",
  /**
   * Number field
   * Supports any number value
   */
  NUMBER = "NUMBER",
  /**
   * Select field
   * Supports a list of options
   */
  SELECT = "SELECT",
  /**
   * Boolean field
   * Supports true or false value
   */
  BOOLEAN = "BOOLEAN",
  /**
   * Date field
   * Supports date value
   */
  DATE = "DATE",
  /**
   * Date range field
   * Supports a list of precomputed options, generated by the type configuration
   * @example Tenure
   */
  DATE_RANGE_ENUM = "DATE_RANGE_ENUM",
  /**
   * Team field
   * Supports a team reference
   * @example Direct team, Indirect team, Custom team.
   */
  TEAM = "TEAM",
  /**
   * Employee field
   * Supports an employee reference
   * @example Manager, HRBP.
   */
  EMPLOYEE = "EMPLOYEE",
}

export interface EmployeeField {
  fieldId: string;
  fieldCode: string;
  dbColumn: string | null;
  type: EmployeeFieldType;
  typeConfig: Record<string, unknown>;
  translations: {
    es: string;
    en: string;
    pt: string;
  };
  options: EmployeeFieldOptions;
  isNonRemovable: boolean;
  createdAt: Date;
  updatedAt: Date;
}

/**
 * Cache for employee fields definition by organization
 */
const cache = new Map<string, EmployeeField[]>();

export async function getEmployeeFieldsDefinition(organizationReference: string): Promise<EmployeeField[]> {
  if (cache.has(organizationReference)) {
    return cache.get(organizationReference)!;
  }

  const response = await axios.get<EmployeeField[]>("/v1/employees/fields");

  cache.set(organizationReference, response.data);

  return response.data;
}

interface Segment {
  id: string;
  name: string;
  filters: Record<string, unknown>;
  ownerType: SegmentOwnerType;
  ownerReference: string;
  createdAt: string;
  updatedAt: string;
}

export async function getSegment(ownerType: SegmentOwnerType, ownerReference: string): Promise<Segment> {
  const response = await axios.get<Segment>(`/v1/segments/${ownerType}/${ownerReference}`);
  return response.data;
}

export async function createOrUpdateSegment(
  ownerType: SegmentOwnerType,
  ownerReference: string,
  filters: Record<string, unknown>
): Promise<void> {
  const body = { filters };

  if (ownerType === SegmentOwnerType.USER) {
    body["userReference"] = ownerReference;
  }
  if (ownerType === SegmentOwnerType.ROLE) {
    body["roleReference"] = ownerReference;
  }
  if (ownerType === SegmentOwnerType.HRBP) {
    body["employeeReference"] = ownerReference;
  }

  await axios.post(`/v1/segments/${ownerType}`, body);
}
