import { dashboardAxios as axios } from "@/lib/axios";
import {
  DriverMetrics,
  TeamMetrics,
  Team,
  TeamType,
  EmployeeBasics,
  EmployeeIdsSegmentFilter,
  CustomSegmentFilter,
  TeamSegmentFilter,
  DepartmentSegmentFilter,
  TeamWithMembers,
  TenureGroup,
} from "@/types/common";
import { fillObjectKeys } from "@/utils/fillMissingData";
import { ListResult, Paginable } from "@alanszp/core";
import { camelCase, isArray, isNumber, isString, merge } from "lodash";
import { DriversWithSubDrivers, fillEmptySubDrivers, getExperienceDriverCodes } from "./driverService";

export function findTeamByLeader(teams: Team[], leaderId: string | undefined, types: TeamType[]): Team | null {
  return types.reduce((acc, type) => {
    if (!acc) {
      return findTeamByLeaderAndType(teams, leaderId, type);
    }

    return acc;
  }, null);
}

function findTeamByLeaderAndType(teams: Team[], leaderId: string | undefined, type: TeamType): Team | null {
  if (teams.length === 0 || leaderId === null) return null;

  const result = teams.find((t) => {
    const sameId = t.managers.length > 0 && String(t.managers[0].id) === leaderId;
    const sameType = type ? t.type === type : true;
    return sameId && sameType;
  });

  if (result) return result;

  return findTeamByLeaderAndType(
    teams.flatMap((t) => t.childrenTeams),
    leaderId,
    type
  );
}

export enum PastTimeOptions {
  FROM_90_DAYS = "from90days",
}

export async function getTeamMetrics(
  teamId: string,
  driversMetadata: DriversWithSubDrivers[],
  pastTime?: PastTimeOptions
): Promise<TeamMetrics> {
  const params: { pastTime?: PastTimeOptions } = {};

  if (pastTime) {
    params.pastTime = pastTime;
  }
  const response = await axios.get<TeamMetrics>(`/v2/dashboards/teams/${teamId}`, {
    params,
  });

  const driversMetrics = fillObjectKeys(response.data.driversMetrics, getExperienceDriverCodes(driversMetadata), {
    value: 0,
  }) as DriverMetrics;

  const withSubDrivers = fillEmptySubDrivers(driversMetrics, driversMetadata);

  return {
    ...response.data,
    driversMetrics: withSubDrivers,
  };
}

export type RawTeam = Omit<Team, "childrenTeams" | "countDirectReports" | "countIndirectReports">;

interface ListTeamMetricsReturn {
  teams: Team[];
  hasMultipleTeams: boolean;
}

export function mapTreeForAutomaticTeams(rawTeams: RawTeam[]): ListTeamMetricsReturn {
  const map: Record<string, number> = {};
  const newTeams: Team[] = [];
  const roots: Team[] = [];
  let hasMultipleTeams = false;

  for (let i = 0; i < rawTeams.length; i += 1) {
    if (rawTeams[i].managers[0]) {
      map[`${rawTeams[i].managers[0].id}_${rawTeams[i].type}`] = i;
    }

    newTeams[i] = merge({}, rawTeams[i], { childrenTeams: [] as Team[] });
  }

  newTeams.forEach((node) => {
    if (node.managers.length === 0 || node.managers[0].principalManagerId === null || node.type === TeamType.CUSTOM)
      return roots.push(node);

    const principalManagerIndex = map[`${node.managers[0].principalManagerId}_${node.type}`];
    if (!isNumber(principalManagerIndex)) return roots.push(node);
    hasMultipleTeams = true;

    newTeams[principalManagerIndex].childrenTeams?.push(node);
  });

  return {
    teams: roots,
    hasMultipleTeams: hasMultipleTeams || roots.length > 1,
  };
}

export interface ListTeamsParams extends Partial<Paginable<true>> {
  type?: TeamType;
  viewAs?: string;
  s?: string;
  managerId?: string;
}

const listTeamsCache: Record<string, ListResult<RawTeam>> = {};

export async function listTeams(params?: ListTeamsParams): Promise<ListResult<RawTeam>> {
  const cacheKey = JSON.stringify(params);

  if (!listTeamsCache[cacheKey]) {
    const response = await axios.get<ListResult<RawTeam>>(`/v1/teams`, { params });
    listTeamsCache[cacheKey] = response.data;
  }

  return listTeamsCache[cacheKey];
}

export async function managerIsBaseLine(managerEmployeeReference: string): Promise<boolean> {
  const teams = await searchTeamsByManager({ managerId: managerEmployeeReference });
  return teams.elements[0]?.indirectReportTeamMembers === teams.elements[0]?.directReportTeamMembers;
}

export async function getTeam(teamId: string): Promise<TeamWithMembers> {
  const response = await axios.get<TeamWithMembers>(`/v1/teams/${teamId}?memberFields=firstName,lastName,avatar`);
  return response.data;
}

interface ResponseUpdateTeam {
  status: TeamPendingChangeStatus;
}

export interface UpdateOrCreateTeamBody {
  name: string;
  managers?: string[];
  filters?: {
    employee_ids?: string[];
  };
}

export async function updateTeam(teamId: string, body: UpdateOrCreateTeamBody): Promise<ResponseUpdateTeam> {
  const response = await axios.patch<ResponseUpdateTeam>(`/v1/teams/${teamId}`, body);

  return response.data;
}

export async function createTeam(body: UpdateOrCreateTeamBody): Promise<ResponseUpdateTeam> {
  const response = await axios.post<ResponseUpdateTeam>(`/v1/teams`, {
    ...body,
    type: TeamType.CUSTOM,
  });
  return response.data;
}

export enum TeamPendingChangeAction {
  MODIFICATION = "modification",
  CREATION = "creation",
}

export enum TeamPendingChangeStatus {
  DENIED = "denied",
  APPROVED = "approved",
  PENDING_APPROVAL = "pending_approval",
}

export interface TeamsPendingChange {
  id: string;
  action: TeamPendingChangeAction;
  status: TeamPendingChangeStatus;
  requestedByUser: { id: string; firstName: string; lastName: string };
  requestedAt: string;
  changes: {
    name?: {
      new: string;
      old?: string;
    };
    manager_ids?: {
      added?: EmployeeBasics[];
      deleted?: EmployeeBasics[];
    };
    employee_ids?: {
      added?: EmployeeBasics[];
      deleted?: EmployeeBasics[];
    };
  };
}

export async function searchTeamChanges(): Promise<TeamsPendingChange[]> {
  const response = await axios.get<{ pending_changes: TeamsPendingChange[] }>("/v1/teams/changes/pending");
  return response.data.pending_changes;
}

export async function executeTeamChange(change: TeamsPendingChange, approved: boolean): Promise<Team> {
  const response = await axios.post<Team>(`/v1/teams/changes/${change.id}`, { approved });
  return response.data;
}

export function isEmployeeIdsSegmentFilter(filters: CustomSegmentFilter): filters is EmployeeIdsSegmentFilter {
  return isArray((filters as EmployeeIdsSegmentFilter).employee_ids);
}

export function isTeamSegmentFilter(filters: CustomSegmentFilter): filters is TeamSegmentFilter {
  return isString((filters as TeamSegmentFilter).team);
}

export function isDepartmentSegmentFilter(filters: CustomSegmentFilter): filters is DepartmentSegmentFilter {
  return isString((filters as DepartmentSegmentFilter).department);
}

export function getDepartmentOrTeamSegment(
  filters: CustomSegmentFilter
): { propName: "department" | "team"; value: string } | undefined {
  if (isTeamSegmentFilter(filters)) {
    return { propName: "team", value: filters.team };
  }

  if (isDepartmentSegmentFilter(filters)) {
    return { propName: "department", value: filters.department };
  }
}

export interface DriverDetailTimelineRow {
  name: string;
  date: string;
  value: number;
}

export interface DriverDetailResponse {
  driverCode: string;
  metrics: {
    teamDriverHistoryMetrics: DriverDetailTimelineRow[];
    organizationDriverHistoryMetrics: DriverDetailTimelineRow[];
    teamSubDriversHistoryMetrics: Record<string, DriverDetailTimelineRow[]>;
    organizationSubDriversHistoryMetrics: Record<string, DriverDetailTimelineRow[]>;
  };
}

const driverDetailCache: Record<string, DriverDetailResponse> = {};

export async function getDriverTeamDetail(teamId: string, driver: string): Promise<DriverDetailResponse> {
  const cacheKey = `${teamId}-${driver}`;

  if (!driverDetailCache[cacheKey]) {
    const response = await axios.get<DriverDetailResponse>(`/v2/dashboards/teams/${teamId}/driver/${driver}`);
    driverDetailCache[cacheKey] = response.data;
  }

  return driverDetailCache[cacheKey];
}

export enum MetricType {
  LARA_SCORE = "laraScore",
  RESPONSE_RATE = "responseRate",
  ENPS = "eNPS",
  AVERAGE_MOOD = "averageMood",
}

export interface CompareTeamsMetricsWithOrganization {
  organization: number;
  teams: {
    teamId: string;
    employeeReference: string;
    teamName: string;
    teamSize: number;
    value: number;
    department: string;
    subDepartment: string;
    country: string;
    tenureGroup: TenureGroup;
    location: string;
    jobTitle: string;
  }[];
}

export async function compareTeamsMetricsWithOrganization(
  compareMetric: MetricType,
  groupBy: string,
  teamId?: string
): Promise<CompareTeamsMetricsWithOrganization> {
  const response = await axios.get<CompareTeamsMetricsWithOrganization>("v1/dashboards/comparative", {
    params: { metric: compareMetric, groupBy: camelCase(groupBy), teamId },
  });
  return response.data;
}

interface TeamsByManagerProps extends Partial<Paginable<true>> {
  viewAs?: string;
  search?: string;
  managerId?: string;
}

export interface TeamsByManager {
  directReportTeamId: string;
  indirectReportTeamId: string;
  managerId: string;
  managerName: string;
  parentManagerId: string | undefined;
  directReportTeamMembers: number;
  indirectReportTeamMembers: number;
}

export async function searchTeamsByManager(params?: TeamsByManagerProps): Promise<ListResult<TeamsByManager>> {
  return (await axios.get<ListResult<TeamsByManager>>("/v1/teams/by-manager", { params })).data;
}
