export enum PermissionCode {
  // User permissions
  USER_IMPERSONATE = "user:impersonate",
  USER_EDIT = "user:edit",
  USER_DELETE = "user:delete",
  USER_CREATE = "user:create",
  USER_RESET_PASSWORD = "user:resetPassword",

  // Organization permissions
  ORGANIZATION_EDIT = "organization:edit",

  // Contacts
  CONTACT_OFFBOARDING_LIST = "contact.offboarding:list",

  // Front sections
  FRONT_SECTION_TEAMS_VIEW = "front.section.teams:view",
  FRONT_SECTION_REPORTING_VIEW = "front.section.reporting:view",
  FRONT_SECTION_MANAGER_MY_TEAM_VIEW = "front.section.manager.myTeam:view",
  FRONT_SECTION_MANAGER_TEAM_COMPARISON_VIEW = "front.section.manager.teamComparison:view",

  // File
  FILE_INTEGRATION_SAMPLE = "file.integration:sample",

  // Boards
  BOARD_EDIT = "board:edit",
  BOARD_SKIP_ACCESS_CHECK = "board:skipAccessCheck",

  // Roles
  ROLE_EDIT = "role:edit",
  ROLE_CREATE = "role:create",
  ROLE_PERMISSION_EDIT = "role.permission:edit",
  ROLE_HOME_BOARD_EDIT = "role.homeBoard:edit",
  ROLE_LIST = "role:list",

  // Integration Channel Permissions
  INTEGRATION_CHANNEL_LIST = "integration.channel:list",
  INTEGRATION_CHANNEL_SYNC = "integration.channel:sync",
  INTEGRATION_CHANNEL_EDIT = "integration.channel:edit",
  INTEGRATION_CHANNEL_CREATE = "integration.channel:create",
  INTEGRATION_CHANNEL_VIEW = "integration.channel:view",
  INTEGRATION_CHANNEL_HISTORY_VIEW = "integration.channel.history:view",

  // Integration HRMS Permissions
  INTEGRATION_HRMS_LIST = "integration.hrms:list",
  INTEGRATION_HRMS_SYNC = "integration.hrms:sync",
  INTEGRATION_HRMS_EDIT = "integration.hrms:edit",
  INTEGRATION_HRMS_CREATE = "integration.hrms:create",
  INTEGRATION_HRMS_VIEW = "integration.hrms:view",
  INTEGRATION_HRMS_HISTORY_VIEW = "integration.hrms.history:view",
}
