import { parseArrayValue } from "@/components/filters/utils";
import { isNil } from "lodash";

export function objectToQueryParams<T extends object>(values: T): string {
  const urlSearchParams = new URLSearchParams();

  Object.keys(values)
    .sort()
    .forEach((key) => {
      if (!isNil(values[key])) {
        urlSearchParams.set(key, values[key]);
      }
    });

  return urlSearchParams.toString();
}

export function urlSearchParamsToObject(params: URLSearchParams) {
  const obj = {};

  for (const key of params.keys()) {
    if (params.getAll(key).length > 1) {
      obj[key] = params.getAll(key);
    } else {
      obj[key] = parseArrayValue(params.get(key) as string);
    }
  }

  return obj;
}
